import React from 'react';
import map from '../images/map-1-1.png';

import '../styles/projects.css';
import '../styles/projects-anims.css'

function PortfolioMap() {
    return (
      <div>
        <div className="portfolio-container">
          <div className="map-wrapper">
            
            <div className="map-title">
              Portfolio
            </div>
  
            <RegionLabel label={"Web Development"} left={15} top={30} />
            <MakeMapPin left={18} top={43} text="Portfolio Website" link="https://www.liampatty.com"/>
            <MakeMapPin left={20} top={25} text="Student Direct" link="https://github.com/csci312a-s23/project-ellen"/>
            <MakeMapPin left={30} top={35} text="Visualizing Mental Health" link="https://github.com/csci0465-f22/project-04-computer-dudes"/>
            <MakeMapPin left={28} top={50} text="MiddXChange" link="https://github.com/AlexisBallo2/Hackathon2022?tab=readme-ov-file"/>
            <MakeMapPin left={22} top={60} text="End-to-end webpages for BlockBar" grey={true}/>
  
            <RegionLabel label={"Machine Learning"} left={60} top={70} />
            <MakeMapPin left={60} top={65} text="GNN Word Embedding" link="https://github.com/liam-hp/Deep-Learning-FinPro"/>
            <MakeMapPin left={53} top={75} text="Deep Attention BERT" link="https://github.com/liam-hp/DABERT2.0"/>
            <MakeMapPin left={49} top={66} text="GATConv Social Prediction" grey={true}/>
            <MakeMapPin left={55} top={58} text="Thesis - BSpline Activation for MLP" grey={true}/>
  
            <RegionLabel label={"App Development"} left={60} top={15} />
            <MakeMapPin left={65} top={13} text="Novelty Item Marketplace" grey={true}/>
            <MakeMapPin left={55} top={30} text="Workout Tracker" grey={true}/>
  
  
            <RegionLabel label={"Other"} left={32} top={72} />
            <MakeMapPin left={33} top={62} text="GHA Workflow Automation" link="https://github.com/liam-hp/build-once-deploy-many"/>
            <MakeMapPin left={43} top={77} text= "Labyrinth Game" link="https://github.com/liam-hp/CS390-FinalProj"/>
            <MakeMapPin left={28} top={74} text= "Pandemic Modeling" link="https://github.com/liam-hp/CS390-PandemicModeling"/>
            <MakeMapPin left={36} top={87} text="Software Assisted Web Nav" link="https://github.com/liam-hp/Software-Assisted-Web-Nav"/>
            <MakeMapPin left={24} top={83} text="Point cloud generation from photos" link="https://github.com/liam-hp/SLS-Hackathon-20"/>
            <MakeMapPin left={28} top={93} text="Renewable energy analysis (R)" link="https://github.com/liam-hp/ECONSTATS-Blog-Post"/>
            <MakeMapPin left={31} top={83} text="Visual cortex spike analysis (R)" link="https://github.com/liam-hp/DSCI-Across-Disciplines-Final"/>
            <MakeMapPin left={33} top={73} text="Custom Catering Proposal Generator" grey={true}/>
  
            <div className="tl-tape" />
            <div className="tr-tape" />
            <div className="bl-tape" />
            <div className="br-tape" />
  
            <img src={map} alt='' className="map"/>
          </div>
        </div>
      </div>
    )
  }
  
function RegionLabel(props){
  return(
    <p className="region-label" style={{left: `${props.left}%`, top: `${props.top}%`}}> {props.label} </p>
  );
}

function MakeMapPin(props){
  const additional_props = (props.grey != undefined && props.grey == true )?  "grey-pin" : "";
  return(
    <div className="map-pin" style={{left: `${props.left}%`, top: `${props.top}%`}}>
      <div className="map-pin-needle" />
      <a className={`map-pin-top center ${additional_props}`} href={props.link} target="_blank">
        <p className="map-pin-text"> 
          {props.text}
        </p>
      </a>
    </div>
  )
}

export default PortfolioMap;