import React, { useEffect, useState } from 'react';

import './styles/App.css';

import CorkBoard from './components/Corkboard';
import PortfolioMap from './components/Map';
import Bookshelf from './components/Bookshelf';
import Painting from './components/Painting';

import resume from './files/Resume.pdf'


/* App Wrapper */
function App() {
  /* 

  - escape key to close open book/paper

  ^ Extras:
    - wood texture
    - paper texture

    - bookshelf filling animation
    - book text writes itself animation
    - photo album style for portfolio

    - adjust (variable?) book pull out angle 
    - round the books: https://stackoverflow.com/questions/31765345/how-to-round-out-corners-when-using-css-clip-path
  */

  const [openFile, setOpenFile] = useState(undefined);
  const [openBook, setOpenBook] = useState(undefined);

  useEffect(() => {
    const handleKeyDown = (evt) => {
      const isEscape = evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27;
      if (isEscape) {
        setOpenBook(undefined);
        setOpenFile(undefined);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className="App col">
        <OpenPaper file={openFile} setOpenFile={setOpenFile}/>
        <OpenBook book={openBook} setOpenBook={setOpenBook}/>

        <div className="row">
          <Painting />
          <CorkBoard setOpenFile={setOpenFile}/>
        </div>
        <div className='row' style={{width: '98.5%', justifyContent: 'space-between', marginLeft: '2%'}}>
          <Bookshelf setOpenBook={setOpenBook}/>
          <PortfolioMap />
        </div>
      </div>

      <div className="mobile"> 
        <p className='mobile-text-1'> This site is not yet optimized for mobile. </p>
        <p className='mobile-text-3'>
          Feel free to check out my <a href={resume} download="WilliamPattyResume">Resume</a>, <a href='https://www.linkedin.com/in/william-p-888001182/' target="_blank"> LinkedIn</a>, or <a href="https://github.com/liam-hp" target="_blank"> GitHub</a> in the meantime.
        </p>
      </div>
    </>
  );
}



function OpenBook({book, setOpenBook}) {
  if (book===null || book===undefined) {
    return <></>;
  }
  return(
    <>
      <div className='open-paper-bg center' onClick={()=>setOpenBook(undefined)}>
        <div className='select-wrapper center row' onClick={(e)=>{e.stopPropagation();}}>
              <embed
                src={`${book[0]}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0`}
                className='select-view book-specs'
                frameborder="0"
                allowtransparency="true"
              />
              <div className="book-border paper-border" 
                  style={{left: '35vw'}}/>
              <div className='book-divider'/>
              <embed
                src={`${book[1]}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0`}
                className='select-view book-specs'
                frameborder="0"
                allowtransparency="true"
              />
              <div className="book-border paper-border" 
                  style={{right: '35vw'}}/>
          </div>
      </div>
    </>
  )
}

function OpenPaper({file, setOpenFile}){
  if (file===null || file===undefined){
    return <></>;
  }
  return(
    <>
      <div className='open-paper-bg center' onClick={()=>setOpenFile(undefined)}>
        <div className='select-wrapper center' onClick={(e)=>{e.stopPropagation();}}>
            <iframe
              src={`${file}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0`}
              className='select-view paper-specs'
            />
            <div className="paper-border" />
        </div>
      </div>
    </>
  );
}

export default App;

