import React, { useEffect, useState } from 'react';

import github_logo from '../images/github.png';
import gmail_logo from '../images/gmail.png';
import linkedin_logo from '../images/linkedin.png';

import line2 from '../images/lines/Line2.svg';
import line3 from '../images/lines/Line3.svg';

import '../styles/contact.css';
import '../styles/contact-anims.css'

// https://reiinakano.com/arbitrary-image-stylization-tfjs/
// https://huggingface.co/spaces/svjack/Super-Resolution-Neural-Style-Transfer

function Painting() {
    const [hasLoaded, setHasLoaded] = useState(false);
  
    useEffect(() => {
      // This code runs once on component mount
      const timer = setTimeout(() => {
        setHasLoaded(true);
      }, 1680); // Delay in milliseconds, adjust as needed
      return () => clearTimeout(timer);
    }, []);
  
    return(
      <div style={{width: '16vw'}}>
        <div className="painting-container">
            <div className="strings-container">
              <img className="string1" alt="" src={line2} />
              <img className="string2" alt="" src={line3} />
              <div className="ellipse" />
            </div>
            <div className="painting-border">
              <GetPainting />
            </div>
        </div>
        <div className="socials-container">
          <a className="social-wrap" href="https://www.linkedin.com/in/william-p-888001182/" target="_blank">
            <div className="string-onload socials-string"/>
            <img className={`${hasLoaded ? '' : 'logo-onload'} linkedin logo`} alt="" src={linkedin_logo} />
          </a>
          <a className="social-wrap" href="https://github.com/liam-hp" target="_blank">
            <div className="string-onload socials-string"/>
            <img className={`${hasLoaded ? '' : 'logo-onload'} github logo`} alt="" src={github_logo} />
          </a>
          <a className="social-wrap" href="mailto:liamh.patty@gmail.com" target="_blank">
            <div className="string-onload socials-string"/>
            <div className={`${hasLoaded ? '' : 'logo-onload'} gmail-container logo center`}>
              <img className="gmail" alt="Gmail" src={gmail_logo} />
            </div>
          </a>
        </div>
      </div>
    );
}
  
function GetPainting(){
  
    // https://reiinakano.com/arbitrary-image-stylization-tfjs/
    // https://neural-style-transfer-app.ew.r.appspot.com/
  
    let count = 34;
    const [key, setKey] = useState(-1); // Math.ceil(Math.random() * count)
    const [unusedKeys, setUnusedKeys] = useState([...Array(count).keys()]);

    const restartAnimation = (logo) => {
      // Force reflow/repaint
      logo.style.animation = 'none';
      void logo.offsetHeight; // Trigger reflow to reset the animation
      logo.style.animation = ''; // Clear the forced none
      logo.style.filter = 'brightness(70%)';
      logo.style.animation = 'vertical-shaking 1s';
    };
    
    const refreshPainting = () => {
      let border = document.getElementsByClassName("painting-border")[0];
      let painting = document.getElementsByClassName("painting")[0];
      let string1 = document.getElementsByClassName("string1")[0];
      let string2 = document.getElementsByClassName("string2")[0];
      let logos = document.getElementsByClassName("logo");
      let strings = document.getElementsByClassName("string-onload");
      let socialwraps = document.getElementsByClassName("social-wrap");
      
      // remove the animation so we can retrigger it
      border.style.animation = "none";
      painting.style.animation = "none";
      string1.style.animation = "none";
      string2.style.animation = "none";
      
      // trigger a reflow
      void border.offsetHeight;
      void painting.offsetHeight; 
      void string1.offsetHeight;
      void string2.offsetHeight;
      
      border.style.animation = "vertical-drop-painting 1.7s ease-in-out"
      painting.style.animation = "fade-in 1s ease-in-out"
      string1.style.animation = "right-string 1.7s ease-in-out"
      string2.style.animation = "left-string 1.7s ease-in-out"
  
      for (let logo of logos) {
        logo.style.animation = "none";
        void logo.offsetHeight;
        logo.style.animation = "vertical-drop 1.7s ease-in-out"
      }
  
      for (let logostring of strings) {
        logostring.style.animation = "none";
        void logostring.offsetHeight;
        logostring.style.animation = "hide-string 1.7s ease-in-out"
      }
  
      for (let wrap of socialwraps) {
          wrap.addEventListener('mouseenter', () => {
            const logos = wrap.querySelectorAll('.logo');
            logos.forEach(logo => {
              restartAnimation(logo);
            });
          });
        
          wrap.addEventListener('mouseleave', () => {
            const logos = wrap.querySelectorAll('.logo');
            logos.forEach(logo => {
              // Reset styles on mouse leave if necessary
              logo.style.filter = 'none';
              logo.style.animation = 'none'; // You may adjust this based on your desired behavior
            });
          });
      }
  
      // change key to re-render component
      let newKey = -1
      if(unusedKeys.length === 0){
        setUnusedKeys([...Array(count).keys()]);
      }
      else{
        let idx = Math.floor(Math.random() * unusedKeys.length)
        newKey = unusedKeys[idx]
        const updatedKeys = unusedKeys.filter((_, index) => index !== idx);
        setUnusedKeys(updatedKeys)
      }
      setKey(newKey); 
    };
    return (
      <div 
        onClick={refreshPainting}
        className="painting" 
        style={{
          backgroundImage: `url("/paintings v2/${key}.png")`
        }}
      />
    );
}

export default Painting;