import React from 'react';

//import tbd from './files/TBD.pdf';
import empty from '../files/Empty.pdf';

import ml1 from '../files/Machine Learning 1.pdf';
import ml2 from '../files/Machine Learning 2.pdf';
import fs1 from '../files/Full Stack 1.pdf';
import fs2 from '../files/Full Stack 2.pdf';
import apd1 from '../files/App Dev 1.pdf';
import ds1 from '../files/Data Science 1.pdf';
import gd1 from '../files/Game Dev 1.pdf';
import math1 from '../files/Math 1.pdf';

import other from '../files/Other.pdf';

import '../styles/bookshelf.css';
import '../styles/bookshelf-anims.css';

function Bookshelf({setOpenBook}) {
    return (
      <>
        <div className='bookshelf'>
          <div className='row' style={{width: '98%', height: '47%', margin: "1% 0 0 1%"}}>
            <MakeShelf books={["Machine Learning", "Web Dev", "App Dev", "Data Science", "Game Dev", "Math", "Other"]} setOpenBook={setOpenBook}/>
          </div>
          <div className='row' style={{width: '98%', height: '47%', margin: "1.3% 0 0 1%"}}>
            <MakeShelf books={[]} setOpenBook={setOpenBook}/>
          </div>
        </div>
      </>
    );
}
  
function MakeShelf({books, setOpenBook}) {
  
    const titleToContent = {
      "Machine Learning": [ml1, ml2],
      "Web Dev": [fs1, fs2],
      "App Dev": [apd1, empty],
      "Data Science": [ds1, empty],
      "Game Dev": [gd1, empty],
      "Math": [math1, empty],
      "Other": [other, empty],
    }
  
    return (
      <div className="innerbox-container">
        <div>
          <div className="innerboxline1" />
          <div className="innerboxline2" />
          <div className="innerboxline3" />
        </div>
        <div className='row books-row'>
          {books ? books.map((title, index) => 
            <MakeBook titleText={title} xoffset={index} key={index} setOpenBook={setOpenBook} content={titleToContent[title]}/>) : null
          }
        </div>
      </div>
    )
}
  
const MakeBook = ({xoffset, titleText, setOpenBook, content}) =>
{
    const cardStyle = {
      marginLeft: `${xoffset*7.9}%`,
      zIndex: `${10-xoffset}`,
    }
  
    return(
      <div>
        <div className='card' style={cardStyle}>
          <div className='base' onClick={() => setOpenBook(content)}>
            <div className='textE' style={{marginLeft: `1vw`}}>{titleText}</div>
          </div>
          <div className='base-outline' />
  
          <div className='top'></div>
          <div className='top-outline'></div>
  
          <div className='left'></div>
          <div className='left-outline' />
        </div>
      </div>
    );
}
  
export default Bookshelf;